var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "search-area" }, [
        _c("div", { staticClass: "title" }, [_vm._v("需求订单")]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "name" }, [_vm._v("需求编号")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-input", {
                model: {
                  value: _vm.demandNo,
                  callback: function($$v) {
                    _vm.demandNo = $$v
                  },
                  expression: "demandNo"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "name mar-left" }, [_vm._v("发布时间")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间"
                },
                model: {
                  value: _vm.beginTime,
                  callback: function($$v) {
                    _vm.beginTime = $$v
                  },
                  expression: "beginTime"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "space" }, [_vm._v("至")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间"
                },
                model: {
                  value: _vm.endTime,
                  callback: function($$v) {
                    _vm.endTime = $$v
                  },
                  expression: "endTime"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-button", on: { click: _vm.searchInit } },
            [_c("i", { staticClass: "el-icon-search" }), _vm._v("搜索 ")]
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "name" }, [_vm._v("下单人")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-input", {
                model: {
                  value: _vm.founder,
                  callback: function($$v) {
                    _vm.founder = $$v
                  },
                  expression: "founder"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "name mar-left" }, [_vm._v("联系电话")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-input", {
                model: {
                  value: _vm.phone,
                  callback: function($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "order-class-list" },
        _vm._l(_vm.stateList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              class: ["order-class-item", { active: _vm.status === item.id }],
              on: {
                click: function($event) {
                  _vm.status = item.id
                }
              }
            },
            [_vm._v(" " + _vm._s(item.desc) + " ")]
          )
        }),
        0
      ),
      _vm._l(_vm.orderList, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticStyle: {
              "margin-bottom": "30px",
              "background-color": "white",
              padding: "20px"
            }
          },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [_vm._v("需求编号:" + _vm._s(item.demandNo))])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [_vm._v("下单人:" + _vm._s(item.userName))])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [_c("div")])
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", [_vm._v("下单时间:" + _vm._s(item.createTime))])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _vm._v("联系电话:" + _vm._s(item.contactsPersonPhone))
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [_c("div")])
              ],
              1
            ),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: [item],
                  "header-cell-style": {
                    "background-color": "#fafafa"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "货品名称",
                    width: "200"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "ellipsis",
                                on: {
                                  click: function($event) {
                                    return _vm.getDetailMessage(
                                      scope.row.productName
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.productName) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "designDocuments",
                    label: "设计文件",
                    width: "200"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.designDocuments
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.downLoadFile(
                                          scope.row.designDocuments
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("文件下载")]
                                )
                              : _c("span", [_vm._v("未上传")])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "count", label: "数量", width: "100" }
                }),
                _c("el-table-column", {
                  attrs: { label: "需求状态", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("orderStatusDesc")(
                                    scope.row.orderStatus
                                  )
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "describe", label: "需求描述", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "ellipsis",
                                on: {
                                  click: function($event) {
                                    return _vm.getDetailMessage(
                                      scope.row.describe
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(scope.row.describe) + " ")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "remark", label: "备注", width: "200" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "ellipsis",
                                on: {
                                  click: function($event) {
                                    return _vm.getDetailMessage(
                                      scope.row.remark
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(scope.row.remark) + " ")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "", label: "详情", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.orderSerialCode
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.checkDetail(item)
                                      }
                                    }
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "orderSerialCode",
                    label: "关联的订单号",
                    width: "200"
                  }
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "200" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.orderStatus == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.perfectOrder(item)
                                      }
                                    }
                                  },
                                  [_vm._v("完善订单")]
                                )
                              : _vm._e(),
                            scope.row.orderStatus == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.rejuct(item)
                                      }
                                    }
                                  },
                                  [_vm._v("拒绝")]
                                )
                              : _vm._e(),
                            scope.row.orderStatus == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.perfectOrder(item)
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "div",
        { attrs: { align: "right" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.totalCount
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "订单详情", visible: _vm.orderDetailLog },
          on: {
            "update:visible": function($event) {
              _vm.orderDetailLog = $event
            }
          }
        },
        [
          _c(
            "el-table",
            { attrs: { data: [_vm.detail] } },
            [
              _c("el-table-column", {
                attrs: { property: "skuCode", label: "货品规格", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("skuCodeInfo")(scope.row.skuCode)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  property: "designDocuments",
                  label: "文件",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.designDocuments
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "warning" },
                                on: {
                                  click: function($event) {
                                    return _vm.downLoadFile(
                                      scope.row.designDocuments
                                    )
                                  }
                                }
                              },
                              [_vm._v("文件下载")]
                            )
                          : _c("span", [_vm._v("未上传")])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "price", label: "货品金额", width: "150" }
              }),
              _c("el-table-column", {
                attrs: { property: "amount", label: "数量", width: "150" }
              }),
              _c("el-table-column", {
                attrs: { property: "price", label: "订单金额", width: "150" }
              }),
              _c(
                "el-table-column",
                { attrs: { property: "", label: "操作" } },
                [
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "warning" },
                        on: {
                          click: function($event) {
                            _vm.orderDetailLog = false
                          }
                        }
                      },
                      [_vm._v("返回")]
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "详情", visible: _vm.detailInfo, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.detailInfo = $event
            }
          }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.detailMessage))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.detailInfo = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }