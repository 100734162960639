<template>
  <div class="setAvtive" style="height: 100%">
    <listNull text="此功能暂未开启~" />
  </div>
</template>

<script>
import listNull from '@/components/listNull.vue'

export default {
  components: {
    listNull
  }
}
</script>

<style>

</style>