<template>
  <div>
    <div class="search-area">
      <div class="title">需求订单</div>
      <div class="row">
        <div class="name">需求编号</div>
        <div class="input">
          <el-input v-model="demandNo"></el-input>
        </div>
        <!-- <div class='name mar-left'>收货人</div>
              <div class='input'><el-input></el-input></div> -->
        <div class="name mar-left">发布时间</div>
        <div class="input">
          <el-date-picker
            v-model="beginTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="space">至</div>
        <div class="input">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="search-button" @click="searchInit">
          <i class="el-icon-search"></i>搜索
        </div>
      </div>
      <div class="row">
        <div class="name">下单人</div>
        <div class="input"><el-input v-model="founder"></el-input></div>
        <div class="name mar-left">联系电话</div>
        <div class="input"><el-input v-model="phone"></el-input></div>
      </div>
    </div>
    <div class="order-class-list">
      <div
        @click="status = item.id"
        :class="['order-class-item', { active: status === item.id }]"
        v-for="(item, index) in stateList"
        :key="index"
      >
        {{ item.desc }}
      </div>
    </div>
    <div
      v-for="(item, index) in orderList"
      :key="index"
      style="margin-bottom: 30px; background-color: white; padding: 20px"
    >
      <el-row>
        <el-col :span="6"
          ><div>需求编号:{{ item.demandNo }}</div></el-col
        >
        <el-col :span="8"
          ><div>下单人:{{ item.userName }}</div></el-col
        >
        <el-col :span="8"><div></div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"
          ><div>下单时间:{{ item.createTime }}</div></el-col
        >
        <el-col :span="8"
          ><div>联系电话:{{ item.contactsPersonPhone }}</div></el-col
        >
        <el-col :span="8"><div></div></el-col>
      </el-row>
      <el-table
        :data="[item]"
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#fafafa',
        }"
      >
        <el-table-column prop="productName" label="货品名称" width="200">
          <template slot-scope="scope">
            <div
              @click="getDetailMessage(scope.row.productName)"
              class="ellipsis"
            >
              {{ scope.row.productName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="designDocuments" label="设计文件" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.designDocuments"
              size="mini"
              type="warning"
              @click="downLoadFile(scope.row.designDocuments)"
              >文件下载</el-button
            >
            <span v-else>未上传</span>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" width="100">
        </el-table-column>
        <el-table-column label="需求状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.orderStatus | orderStatusDesc }}
          </template>
        </el-table-column>
        <el-table-column prop="describe" label="需求描述" width="100">
          <template slot-scope="scope">
            <div @click="getDetailMessage(scope.row.describe)" class="ellipsis">
              {{ scope.row.describe }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="200">
          <template slot-scope="scope">
            <div @click="getDetailMessage(scope.row.remark)" class="ellipsis">
              {{ scope.row.remark }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="详情" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderSerialCode"
              size="mini"
              type="warning"
              @click="checkDetail(item)"
              >详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="orderSerialCode"
          label="关联的订单号"
          width="200"
        >
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderStatus == 0"
              size="mini"
              type="warning"
              @click="perfectOrder(item)"
              >完善订单</el-button
            >
            <el-button
              v-if="scope.row.orderStatus == 0"
              size="mini"
              type="warning"
              @click="rejuct(item)"
              >拒绝</el-button
            >
            <el-button
              v-if="scope.row.orderStatus == 1"
              size="mini"
              type="warning"
              @click="perfectOrder(item)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div align="right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-dialog title="订单详情" :visible.sync="orderDetailLog">
      <el-table :data="[detail]">
        <el-table-column property="skuCode" label="货品规格" width="150">
          <template slot-scope="scope">
            {{ scope.row.skuCode | skuCodeInfo }}
          </template>
        </el-table-column>
        <el-table-column property="designDocuments" label="文件" width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.designDocuments"
              size="mini"
              type="warning"
              @click="downLoadFile(scope.row.designDocuments)"
              >文件下载</el-button
            >
            <span v-else>未上传</span>
          </template>
        </el-table-column>
        <el-table-column
          property="price"
          label="货品金额"
          width="150"
        ></el-table-column>
        <el-table-column
          property="amount"
          label="数量"
          width="150"
        ></el-table-column>
        <el-table-column
          property="price"
          label="订单金额"
          width="150"
        ></el-table-column>
        <el-table-column property="" label="操作">
          <template slot-scope>
            <el-button
              size="mini"
              type="warning"
              @click="orderDetailLog = false"
              >返回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="detailInfo" width="30%">
      <span>{{ detailMessage }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailInfo = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pageListByOperationalRole, queryBookingInfoFromCartById, demandorderUpdateStatus, stateList } from '@/api/printonline'
import { downLoadFile } from '@/lib/util'
import { orderdemandoperatorsQueryPage, orderdemandqueryDetail, orderdemandrefuse } from '@/api/cubeclient'
import axios from 'axios'
export default {
  data () {
    return {
      orderList: [],
      demandNo: '',
      beginTime: '',
      endTime: '',
      founder: '',
      phone: '',
      orderDetailLog: false,
      detail: {},
      status: '',
      state: [],
      detailMessage: '',
      detailInfo: false,
      stateList: [
        { id: '', desc: '全部' },
        { id: 0, desc: '待完善' },
        { id: 1, desc: '已完善' },
        // {id:2,desc:'待确认'},
        { id: 3, desc: '已确认' },
        { id: 4, desc: '已取消' },
        { id: 5, desc: '已拒绝' }
      ],
      page: 1,
      limit: 10,
      totalCount: 0
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    downLoadFile (url) {
      const type = url.split('.').pop()
      downLoadFile(url, type)
    },
    getfile (url) {

    },
    handleSizeChange (val) {
      this.limit = val
      this.page = 1
      this.search()
    },
    handleCurrentChange (val) {
      this.page = val
      this.search()
    },
    getDetailMessage (info) {
      this.detailInfo = true
      this.detailMessage = info
    },
    perfectOrder (item) {
      sessionStorage.setItem('DemandOprder', JSON.stringify(item))
      this.$router.push({ name: 'OnlinePrinting' })
    },
    async rejuct (order) {
      const res = await orderdemandrefuse({
        demandNo: order.demandNo,
      })
      this.search()
    },
    async checkDetail (order) {
      const res = await orderdemandqueryDetail({
        demandNo: order.demandNo
      })
      this.detail = { ...order, ...res.data, }
      this.orderDetailLog = true
    },
    async searchInit () {
      this.page = 1
      this.search()
    },
    async search () {
      const param = {
        createTimeBegin: this.beginTime,
        createTimeEnd: this.endTime,
        demandNo: this.demandNo,
        pageNo: this.page,
        pageSize: this.limit,
        userName: this.founder,
        userPhone: this.phone,
      }


      const res = await orderdemandoperatorsQueryPage(param)
      this.orderList = res.data.list
      this.totalCount = res.data.total
      // res = await stateList(param)
      // this.state = res.data
    }
  },
  filters: {
    skuCodeInfo (value) {
      if (!value) {
        return ''
      }
      let result = ''
      const skuCodes = JSON.parse(value)
      skuCodes.forEach(skuCode => {
        for (const key in skuCode) {
          result = result + `${key}:${skuCode[key]}  `
        }
      })
      return result
    },
    stateName (value) {
      switch (value) {
        case 'all':
          return '全部'
        case 'toBeImproved':
          return '待完善'
        case 'improved':
          return '已完善'
        case 'toBeConfirmed':
          return '待确认'
        case 'confirmed':
          return '已确认'
        case 'cancelled':
          return '已取消'
        case 'rejected':
          return '已拒绝'
        default:
          return ''
      }
    },
    orderStatusDesc (index) {
      //  //  0 待完善，1 已完善，2 待确认，3 已确认，4 已取消 ，5 已拒绝
      switch (String(index)) {
        case '0':
          return '待完善'
        case '1':
          return '已完善'
        case '2':
          return '待确认'
        case '3':
          return '已确认'
        case '4':
          return '已取消'
        case '5':
          return '已拒绝'
        default:
          return ''
      }
    }
  },
  watch: {
    status (newV, oldV) {
      this.page = 1
      this.search()
    }
  }

}
</script>

<style lang="scss" scoped>
.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.search-area {
  width: 100%;
  height: 219px;
  background: #ffffff;
  padding-left: 30px;
  .title {
    padding-top: 20px;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #191c3d;
    margin-bottom: 30px;
  }
  .row {
    width: 100%;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
    .name {
      padding-right: 10px;
      float: left;
      font-size: 14px;
      line-height: 40px;
      width: 70px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #191c3d;
    }
    .input {
      float: left;
      width: 200px;
    }
    .space {
      float: left;
      line-height: 40px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      padding: 0 10px 0 10px;
      margin-left: 20px;
      // margin-right: 20px;
    }
    .mar-left {
      margin-left: 55px;
    }
    .select {
      float: left;
      width: 120px;
    }
    .search-button {
      cursor: pointer;
      position: absolute;
      right: 50px;
      width: 100px;
      height: 40px;
      background: #2468f2;
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.order-class-list {
  margin-top: 16px;
  width: 100%;
  height: 56px;
  background: #e6e6e6;
  .active {
    background: #ffffff;
  }
  .order-class-item {
    float: left;
    width: 120px;
    height: 56px;
    text-align: center;
    line-height: 56px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #191c3d;
  }
}

.order-item {
  width: 100%;
  background: #ffffff;
  margin-top: 20px;
  padding: 20px;
}
</style>
